<template>
  <div class="main" v-if="!isLoading" ref="element">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Case Study Report</b></h1>
      </div>
      <div class="column bar">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Case Study</h3>
            </li>
            <li>
              <router-link :to="{ name: `report` }">
                <h3 class="is-size-6">Case Study Report</h3>
              </router-link>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">
                {{ report.caseStudy }} - NIT: {{ report.nit }}
              </h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <b-modal :active.sync="isShowImageActive" :width="900">
      <div class="box">
        <img :src="activeImageUrl" width="900" />
      </div>
    </b-modal>
    <div>
      <div class="columns">
        <div class="column is-12">
          <b-tabs type="is-hcc" v-model="activeTab">
            <b-tab-item label="Proctoring">
              <div class="columns is-multiline header-proctoring">
                <div class="column is-10">
                  <label class="title">Summary</label>
                </div>
                <div class="column is-2">
                  <b-button
                    type="is-hcc"
                    @click="downloadImageZip(report.date)"
                  >
                    Download All Zip
                  </b-button>
                </div>
                <div class="column is-12">
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Screen Capture</label>
                    </span>
                    <span class="column is-3">
                      <label class="label">
                        {{ report.screenCapture }} images
                      </label>
                    </span>
                  </span>
                </div>
                <div class="column is-12">
                  <span class="columns">
                    <span class="column is-2">
                      <label class="label">Photo</label>
                    </span>
                    <span class="column is-3">
                      <label class="label">{{ report.camera }} photos</label>
                    </span>
                  </span>
                </div>
              </div>
              <div class="columns">
                <span class="column is-12">
                  <b-table
                    :data="proctoring && proctoring.data ? proctoring.data : []"
                    :current-page.sync="currentPage"
                    paginated
                    backend-pagination
                    :loading="tableLoading"
                    :total="
                      proctoring && proctoring.meta.total
                        ? proctoring.meta.total
                        : 0
                    "
                    :per-page="perPage"
                    @page-change="onPageChange"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    backend-sorting
                    :default-sort-direction="defaultSortOrder"
                    :default-sort="[sortField, sortOrder]"
                    @sort="onSort"
                  >
                    <b-table-column
                      field="index"
                      numeric
                      width="20"
                      label="No"
                      v-slot="props"
                    >
                      {{ props.index + proctoring.meta.from }}
                    </b-table-column>
                    <b-table-column field="url" label="Image" v-slot="props">
                      <img :src="props.row.url" width="100" />
                    </b-table-column>
                    <b-table-column
                      field="created_at"
                      label="Capture At"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.createdAt }}
                    </b-table-column>
                    <b-table-column
                      field="type"
                      label="Type"
                      sortable
                      width="300"
                      v-slot="props"
                    >
                      {{ props.row.type }}
                    </b-table-column>
                    <b-table-column field="action" v-slot="props">
                      <b-button
                        class="button-action"
                        @click="activeModal(props.row.url)"
                      >
                        View
                      </b-button>
                      <b-button
                        class="button-action"
                        @click="
                          download(
                            props.row.date,
                            props.row.image,
                            props.row.type
                          )
                        "
                      >
                        Download
                      </b-button>
                    </b-table-column>
                    <template slot="bottom-left">
                      {{
                        proctoring && proctoring.meta.from
                          ? proctoring.meta.from
                          : 0
                      }}
                      -
                      {{
                        proctoring && proctoring.meta.to
                          ? proctoring.meta.to
                          : 0
                      }}
                      from
                      {{
                        proctoring && proctoring.meta.total
                          ? proctoring.meta.total
                          : 0
                      }}
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon icon="frown" size="is-large"></b-icon>
                          </p>
                          <p>Data not found.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </span>
              </div>
            </b-tab-item>
            <b-tab-item label="Case Study">
              <div class="columns is-multiline header-proctoring">
                <div class="column is-10">
                  <label class="title">Summary</label>
                </div>
                <div class="column is-2">
                  <b-field grouped position="is-right">
                    <b-button type="is-hcc" @click="downloadPdf()">
                      Download PDF
                    </b-button>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div v-html="report.answer"></div>
                </div>
                <!-- <b-table
                  :data="section"
                  :loading="true"
                  :total="
                section.length
              "
                ></b-table>-->
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFullPage: true,
      activeTab: 0,
      isLoading: true,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortField: 'createdAt',
      sortOrder: 'DESC',
      currentPage: 1,
      perPage: 10,
      page: 1,
      search: null,
      surveys: [],
      name: '',
      report: null,
      proctoring: [],
      isShowImageActive: false,
      activeImageUrl: null,
      tableLoading: false,
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {},
  methods: {
    async downloadPdf() {
      var content = []
      content.reportId = this.$route.params.reportId
      await this.$store
        .dispatch('downloadPdfCaseStudy', content)
        .then(response => {
          if (response == 400) {
            this.danger('download failed')
          } else {
            this.success('download complete')
          }
        })
    },
    async downloadImageZip(date) {
      var content = []
      content.date = date
      content.nit = this.report.nit
      content.caseStudyId = this.report.caseStudyId
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      })
      await this.$store
        .dispatch('downloadImageZipCaseStudy', content)
        .then(response => {
          if (response == 400) {
            this.danger('download failed')
          } else {
            this.success('download complete')
          }
          loadingComponent.close()
        })
    },
    async download(date, image, type) {
      var content = []
      content.date = date
      content.image = image
      content.type = type
      content.nit = this.report.nit
      content.caseStudyId = this.report.caseStudyId
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      })
      await this.$store
        .dispatch('downloadImageCaseStudy', content)
        .then(response => {
          if (response == 400) {
            this.danger('download failed')
          } else {
            this.success('download complete')
          }
          loadingComponent.close()
        })
    },
    activeModal(url) {
      this.activeImageUrl = url
      this.isShowImageActive = true
    },
    async fetchDataSurvey() {
      await this.$store.dispatch()
    },
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getReportCaseStudy',
        this.$route.params.reportId
      )
      this.report = response.data
      await this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder
      )
      this.isLoading = false
    },
    async fetchDataProctoring(perPage, page, sortField, sortOrder) {
      if (this.report != null) {
        var content = []
        content.nit = this.report.nit
        content.caseStudyId = this.report.caseStudyId
        content.perPage = perPage
        content.page = page
        content.sortField = sortField
        content.sortOrder = sortOrder
        const responseProctoring = await this.$store.dispatch(
          'getProctoringCaseStudy',
          content
        )
        this.proctoring = responseProctoring
      }
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchDataProctoring(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter
      )
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header-proctoring {
  //   background-color:
  border-bottom: 1px solid #00a3a3;
}
.tabs ul {
  border-bottom-color: #00a3a3 !important;
}
.button-action {
  border: none !important;
  color: #00a3a3;
}
.word-wrap {
  word-break: break-all;
}
</style>
